.home,
#home {
  .siteContainer {
    padding: 0;
  }

  &.user_mode #panelTwo {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.user_mode .theme-main-nav [data-nav-level="1"]{
  .theme-nav-item.home + .theme-nav-item .theme-nav-link:before{
    display: none;
  }
}

$home-mobile-nav-height: 65px;
.has-mobile-nav .home .topNav {
  height: $home-mobile-nav-height;
}
.has-mobile-nav .home .theme-slide-nav-toggle{
  top: #{$home-mobile-nav-height/2};
}
.has-mobile-nav .home .theme-nav-style-slide .theme-nav-title{
  margin-top: $home-mobile-nav-height + 2;
}
// .home:not(.newsPage) #panelTwo:before {
//   box-shadow: none;
// }

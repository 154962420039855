// Aggregator News

// entry
// agg-news-element
// title
// meta
// body
// action (optional)
@mixin clearfix{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}
@function aspect-to-percent($target, $container){
  @return ($container / $target) * 100%;
}

$agg-news-element-entry-title-font: $font-primary;
$agg-news-element-entry-title-size: 18px;
$agg-news-element-entry-title-weight: bold;
$agg-news-element-entry-title-line-height: 1.2;
$agg-news-element-entry-title-color: $color-gray-xdk;
$agg-news-element-entry-title-color-hover: $color-primary;
$agg-news-element-entry-title-case: none;

$agg-news-element-entry-meta-font: $font-secondary;
$agg-news-element-entry-meta-size: 12px;
$agg-news-element-entry-meta-weight: normal;
$agg-news-element-entry-meta-line-height: 1.2;
$agg-news-element-entry-meta-color: $color-gray-xdk;
$agg-news-element-entry-meta-case: none;
$agg-news-element-entry-meta-padding: 5px 0px 0px 0px;

$agg-news-element-entry-body-font: $font-secondary;
$agg-news-element-entry-body-size: 12px;
$agg-news-element-entry-body-weight: normal;
$agg-news-element-entry-body-line-height: 1.2;
$agg-news-element-entry-body-color: $color-gray-dk;
$agg-news-element-entry-body-case: none;
$agg-news-element-entry-body-padding: 5px 0px 0px 0px;

$agg-news-element-entry-preview-image-width: 75px;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(2, 2);
$agg-news-element-entry-preview-image-margin: 0 10px 10px 0;
$agg-news-element-entry-preview-image-float: true;

$agg-news-element-entry-button-padding: 10px 0px 0px 0px;

$agg-news-element-entry-padding-y: 20px;

// RESETS
.newsAggregatorElement{
  .condensed ul{
    list-style: none;
    li:empty{ display: none; }
  } 
}

// Element Header
.newsAggregatorElement{
  .aggHeader{
    @include global-element-header;
  }
}

// List Styles
.newsAggregatorElement{
  .condensed,
  .expanded{
    border-bottom: $global-ui-border-size solid $global-ui-border-color;
    padding: $agg-news-element-entry-padding-y 0px;
  }
  .aggHeader + .condensed,
  .aggHeader + .expanded {
    margin-top: 15px;
    border-top: $global-ui-border-size solid $global-ui-border-color;
  
  }
}  


.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .condensed > a{
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  // ENTRY TITLE
  .condensed .details h4 a{
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .condensed .dateAuthor{
    line-height: 1.2;
    padding: $agg-news-element-entry-meta-padding;
  }
  .condensed .newsAuthor,
  .condensed .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .condensed .teaser{
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
  }

  // ENTRY BUTTON
  .condensed .commentsAndMore{
    padding: $agg-news-element-entry-button-padding;
    .button-content {
      @include styled-link-button;
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .condensed .readMore .button-small{
    // @include global-link-style;
    // @include global-link-container;
    // @include global-link-text($align: center);
    @include styled-link-button;
    display: inline-block;
    
  }
}


// EXPANDED
.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .expanded .dateAuthor{
    line-height: 1.2;
    padding: $agg-news-element-entry-meta-padding;
  }
  .expanded .newsAuthor,
  .expanded .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .expanded .newsItemElement > h4 {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
  }

  // ENTRY BUTTON
  .expanded .commentsAndMore{
    margin: 0;
    .commentCount{
      padding: $agg-news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content{
      @include styled-link-button;
      display: inline-block;     
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .expanded .readMore .button-small{
    // @include global-link-style;
    // @include global-link-container;
    // @include global-link-text($align: center);
    @include styled-link-button;
    display: inline-block;
    
  }
}


// Custom 

:not(.show-news-preview-images){
  .newsAggregatorElement {
    .condensed > a{
      display: none;
    }
  }
}
:not(.show-news-buttons){
  .newsAggregatorElement {
    .condensed .commentsAndMore{
      display: none;
    }
  }
}

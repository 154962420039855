// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
  background-color: #999;
  padding: 70px 0;
  .custom-footer-content{
    @include content-area-width;
    display: flex;
    flex-flow: row;
  }
  .footer-column{
    width: 50%;
    display: flex;
    flex-flow: row;
    @media screen and (max-width: $breakpoint-sm-max) {
    flex-flow: column;
    }
    align-items: flex-start;
    padding: 15px;
    &:first-child{
      box-shadow: inset -1px 0px white;
    }
    &:last-child{
      box-shadow: inset 1px 0px white;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      text-align: center;
    }
  }
  .footer-logo{
    margin: 0 15px;
    @media screen and (max-width: $breakpoint-sm-max) {
      margin: auto;
    }
   img{
      max-width: 115px;
      width: 100%;
      &[src*="LCFHH_Logo.svg"]{
        padding: 0px 10px;
      }
    }
  }
  .footer-links{
    list-style: none;
    flex: 1 1;
    margin: 0 15px;
    @media screen and (max-width: $breakpoint-sm-max) {
      width: 100%;
      margin: 0;
      flex: 0 0;
    }

    a{
      font-size: 12px;
      color: white;
      &:hover{
        color: $color-primary;
      }
    }
  }
}

/* News Slideshow Styles */
// 1. Global Styling
// 2. Thumbnails
// 3. Controls
// 4. Buttons
// 5. Aspect Ratio Adjustments
// 6. Accomidations for Small Columns & Mobile Views

$nss-overlay-color: black;
$nss-overlay-opacity: .7;
$nss-overlay-gradient: $nss-overlay-color, transparent;

$nss-element-title-font-family: $font-primary;
$nss-element-title-text-size: 30px;
$nss-element-title-text-weight: 800;
$nss-element-title-line-height: 1;
$nss-element-title-text-color: white;
$nss-element-title-text-color-hover: white;
$nss-element-title-text-case: null;

$nss-element-description-font-family: $font-primary;
$nss-element-description-text-size: 18px;
$nss-element-description-text-weight: 800;
$nss-element-description-line-height: 1.2;
$nss-element-description-text-color: white;
$nss-element-description-text-color-hover: white;
$nss-element-description-text-case: null;

$nss-element-cta-font-family: null;
$nss-element-cta-text-size: null;
$nss-element-cta-text-weight: null;
$nss-element-cta-line-height: null;
$nss-element-cta-text-color: null;
$nss-element-cta-text-color-hover: null;
$nss-element-cta-text-case: null;

$nss-element-control-area-height: 45px;
$nss-element-control-area-height-hero: 76px;
$nss-element-control-height: 14px;
$nss-element-control-arrow-height: 40px;
$nss-element-control-arrow-width: 40px;
$nss-element-control-arrow-height-hero: 64px;
$nss-element-control-arrow-width-hero: 64px;
// Variables
$control-height: $nss-element-control-area-height;

// 1. Global Styling
.drag .newsSlideShow { cursor: pointer; }

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  &:before{
    content: "";
    position: absolute;
    top: 12%; bottom: 0; left: 0; right: 0;
    background: linear-gradient(to top, #{$nss-overlay-gradient});
    z-index: 1;
  }

  &.current {
    z-index: 1;
  }

  img { position: absolute; }
}

.newsSlideShow-headline {
  position: absolute;
  bottom: $nss-element-control-area-height;
  z-index: 1;
  width: 100%;
  margin: 0;
  text-align: center;
  padding: 0 $nss-element-control-arrow-width;

  // News Slide Show Title
  h1 a{
    display: block;
    font-family: $nss-element-title-font-family;
    font-size: $nss-element-title-text-size;
    font-weight: $nss-element-title-text-weight;
    line-height: $nss-element-title-line-height;
    color: $nss-element-title-text-color;
    text-transform: $nss-element-title-text-case;
    text-align: center;
    &:hover{
      color: $nss-element-title-text-color-hover;
    }
  }
  // Description
  p{
    margin-bottom: 0;
  }
  h1 + p{
    margin-top: 15px;
  }
  p span{
    display: block;
    font-family: $nss-element-description-font-family;
    font-size: $nss-element-description-text-size;
    font-weight: $nss-element-description-text-weight;
    line-height: $nss-element-description-line-height;
    color: $nss-element-description-text-color;
    text-transform: $nss-element-description-text-case;
    text-align: center;
    &:empty { display:none; }
  }
  // CTA
  .newsSlideShow-more.button-small {
    // @include global-link-style;
    // @include global-link-text;
    // @include global-link-icon-style;
    // @include global-link-icon-character;
    @include styled-link-button;
    display: inline-block;
    margin: 20px auto auto auto;
  }
}

// 2. Thumbnails
.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  width: calc(100% - 5rem); // Subtract width of left/right arrows
  height: $nss-element-control-area-height;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  cursor: default;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .col-md-3 & {
    font-size: .5em;
  }

  @media only screen and (max-width: 450px) {
    font-size: .5em;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    padding: 4px;

    .col-md-3 & {
      padding: 6px;
    }

    @media only screen and (max-width: 450px) {
      padding: 6px;
    }

    &:before {
      content: '';
      display: block;
      width: .75em;
      height: .75em;
      border-radius: 100%;
      background-color: black;
      border: 2px solid white;
      transition: background-color $transition-linear;
    }

    &:hover,
    &.current {
      &:before {
        background-color: $color-primary;
      }
    }
  }

  img {
    display: none;
    width: 100%;
  }
}

// 3. Controls
.newsSlideShow-controls {}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  // bottom: 0;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      font-size: 0;
      width: $nss-element-control-arrow-height;
      height: $nss-element-control-arrow-width;
      position: absolute;
      z-index: 10;
      // overflow: hidden;
      padding: 0;
      // font-size: 1em;
      // text-align: center;
      color: white;
      background-color: transparent;
      // border: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      
      &:hover {
        color: $color-primary;
      }
      
  &:before {
    font-size: $nss-element-control-arrow-height;
    line-height: 1;
    content: '\f104'; // fa-arrow-right
    display: block;
    color: inherit;
    font-family: 'fontawesome';
    transition:
      transform $transition-linear,
      color $transition-linear;
  }
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f105'; // fa-arrow-left
}

.newsSlideShow-empty {
  text-align: center;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}



.newsSlideShow-stop,
.newsSlideShow-play { display: none !important; }

// 5. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }

// 6. Accommodations for Small Columns & Mobile Views
@media only screen and (min-width: 767px) {
  .col-md-3,
  .col-md-4 {
    .newsSlideShow-headline {
      h1 a {
        font-size: rem(18);
      }
      p {
        display: none;
      }
    }
  }
  .col-md-3,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    .aspect_ratio_4_1,
    .aspect_ratio_3_1,
    .aspect_ratio_2_1 {
      .newsSlideShow-headline {
        padding-bottom: 0em;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .newsSlideShow-headline {
    .aspect_ratio_4_1 &,
    .aspect_ratio_3_1 &,
    .aspect_ratio_2_1 & {
        padding-bottom: 2em;
    }
    h1 a {
      font-size: rem(18);
    }
    p {
      display: none;
    }
  }
}
@media only screen and (max-width: 350px) {
  .newsSlideShow-headline {
    p {
      display: none;
    }
  }
}


.custom-news-slide-show{
  .newsSlideShow-headline {
    h1 a {
      font-size: 48px;
      @media only screen and (max-width: $breakpoint-sm-max) { font-size: 30px; }
    }
    p span {
      font-size: 24px;
      @media only screen and (max-width: $breakpoint-sm-max) { font-size: 18px; }
    }
    .newsSlideShow-more.button-small {
      // margin: 20px auto auto auto;
    }
  }
  @media only screen and (min-width: $breakpoint-md-min) {
    .newsSlideShow-prev.pill-small-left,
    .newsSlideShow-next.pill-small-right {
      width: $nss-element-control-arrow-height-hero;
      height: $nss-element-control-arrow-width-hero;
      &:before {
        font-size: $nss-element-control-arrow-height-hero;
      }
    }
    .newsSlideShow-headline {
      bottom: $nss-element-control-area-height-hero;
      padding: 0 $nss-element-control-arrow-width-hero;
    }
    .newsSlideShow-thumbnails {
      height: $nss-element-control-area-height-hero;
    }
  }


  // .newsSlideShow-prev,
  // .newsSlideShow-next{
  //   display: none;
  // }
}
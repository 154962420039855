#splash {
  &.user_mode {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: initial;
    padding-bottom: $account-nav-height-desktop;
    .has-mobile-nav & {
      padding-bottom: $account-nav-height-mobile;
    }
    .layoutContainer{
      max-width: 100%;
    }
    .layoutContainer,
    .column{
      padding: 0px;
    }
    .site-background{
      display: none;
    }
    .siteContainer{
      min-height: auto;
    }

    #topNav,
    #topNavPlaceholder,
    #displayBodyHeader,
    .snFooterContainer {
      display: none !important;
    }

    #panelTwo {
      padding: 0 !important;
    }

    // #accountNav {
    //   top: 0 !important;
    // }

    .heroPhotoElement {
      margin: 0px;
      // height: 100vh;
      // width: 100vw;
      // left: 50%;
      // position: fixed;
      // transform: translateX(-50%);
      background: white;
    }
  }
}
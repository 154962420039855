$scoreboard-ribbon-background-color: $color-gray-lt;
$scoreboard-ribbon-padding: 0px;
$scoreboard-ribbon-border-size: 2px;
$scoreboard-ribbon-border-color: white;
$scoreboard-ribbon-height: 120px;

$scoreboard-ribbon-entry-width: 154px;
$scoreboard-ribbon-entry-min-height: $scoreboard-ribbon-height;
$scoreboard-ribbon-entry-background-color: $scoreboard-ribbon-background-color;
$scoreboard-ribbon-entry-background-color-hover: #DDD;
$scoreboard-ribbon-entry-inset-padding-x: 15px;
$scoreboard-ribbon-entry-inset-padding-y: 15px;

$scoreboard-ribbon-arrow-text-size: 30px;
$scoreboard-ribbon-arrow-background-color: $color-gray-lt;
$scoreboard-ribbon-arrow-background-color-hover: #DDD;

$scoreboard-ribbon-arrow-width: 30px;
$scoreboard-ribbon-arrow-height: $scoreboard-ribbon-entry-min-height;

.siteContainer > #masterScoreboard {
  @include content-area-width;
}

#scoreboard_container {
  background-color: $scoreboard-ribbon-background-color;
  border: $scoreboard-ribbon-border-size solid $scoreboard-ribbon-border-color;
  z-index: 1;
  position: relative;
  // border-top: $scoreboard-ribbon-border-size solid $scoreboard-ribbon-border-color;
  // border-bottom: $scoreboard-ribbon-border-size solid $scoreboard-ribbon-border-color;
}



.scoreboardRibbon{
  // min-height: $scoreboard-ribbon-height;
  padding: $scoreboard-ribbon-padding 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;


  .next_button,
  .previous_button {
    flex: 0 0 $scoreboard-ribbon-arrow-width;
    width: $scoreboard-ribbon-arrow-width;
    height: $scoreboard-ribbon-arrow-height;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-family: FontAwesome;
    font-size: $scoreboard-ribbon-arrow-text-size;
    color: $color-gray-dk;
    text-align: center;
    z-index: 500;
    cursor: pointer;
    background-color: $scoreboard-ribbon-arrow-background-color;
    transition: background-color $transition-linear;
    &:hover{
      background-color: $scoreboard-ribbon-arrow-background-color-hover;
    }
    &::selection,
    &::-moz-selection {
      background: transparent;
    }
  }
  .next_button_disabled,
  .previous_button_disabled {
    cursor: default;
    &:before {
      color: white;
    }
  }
  .previous_button {
    &:before {
      content: "\f104";
    }
  }
  .next_button {
    &:before {
      content: "\f105";
    }
  }
  .container{
    flex: 1 1;
    width: auto;
    // height: $scoreboard-ribbon-height;
    padding: 0px $scoreboard-ribbon-border-size;
    // background-color: $scoreboard-ribbon-border-color;
    box-shadow: inset $scoreboard-ribbon-border-size 0px white, inset #{-$scoreboard-ribbon-border-size} 0px white;
    overflow: hidden;
    z-index: 490;
    ul{
      width: 100000px;
      position: relative;
    }
  }


  .game{
    display: block;
    float: left;
    width: $scoreboard-ribbon-entry-width;

  }
  .gameLink{
    min-height: $scoreboard-ribbon-entry-min-height;
    width: 100%;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    cursor: pointer;
    background-color: $scoreboard-ribbon-entry-background-color;
    transition: background-color $transition-linear;
    &:hover{
      background-color: $scoreboard-ribbon-entry-background-color-hover;
    }
  }
  a{
    > span{
      display: block;
    }
  }
  .date,
  .team,
  .status{
    padding-left: $scoreboard-ribbon-entry-inset-padding-x;
    padding-right: $scoreboard-ribbon-entry-inset-padding-x;
  }
  .date{
    margin-top: $scoreboard-ribbon-entry-inset-padding-y;
  }
  .status{
    margin-bottom: $scoreboard-ribbon-entry-inset-padding-y;
  }
  .date{
    color: $color-gray-xdk;
    font-family: $font-primary;
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 8px;
  }
  .team{
    color: $color-gray-xdk;
    font-family: $font-primary;
    font-size: 12px;
    font-weight: 500;
    display: block;
    text-transform: uppercase;
    clear: both;
    &.boom{
      font-weight: 800;
    }
  }

  .game{
    padding-right: $scoreboard-ribbon-border-size;
    box-shadow: inset #{-$scoreboard-ribbon-border-size} 0px white;
    &:last-child {
      padding-right: 0;
      box-shadow: #{$scoreboard-ribbon-border-size} 0px white;
    }
    .name{
      float: left;
      clear: left;
      max-width: 105px;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .score{
      float: right;
    }
    .status{
      color: $color-gray-xdk;
      font-family: $font-primary;
      font-size: 10px;
      font-weight: 800;
      text-transform: uppercase;
      padding-top: 10px;
      clear: both;
    }
  }
  .in_progress .status{
    font-weight: bold;
  }
  .completed .status{
    color: $color-primary;
  }
}
// Shrink ribbon buttons when within 25% column.
@media (min-width: 1000px) {
  .col-md-3 {
    .scoreboardRibbon {
      .container {
        left: 25px;
        right: 25px;
      }
      .previous_button,
      .next_button {
        width: 18px;
      }
      .previous_button {
        left: 4px;
      }
      .next_button {
        right: 4px;
      }
    }
  }
}

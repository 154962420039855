// Config
// Global Layout
// Page Manager
// Page Title

$color-gray-xdk: #333;
$color-gray-dk: #666;
$color-gray: #CCC;
$color-gray-lt: #EEE;
$color-primary: #FF671F;
$color-secondary: #9BCBEB;

// Variables
$desktop-padding: 15px;
$mobile-padding: 10px;

$content-area-width: $maxWidth;
$content-area-padding: ($desktop-padding * 2);
$content-area-padding-mobile: ($mobile-padding * 2);
$content-area-background: #FFF;

$content-container-margin: 0px;
$enable-full-width-layout: true;
$include-page-title-in-content-area-background: true;

$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-intermediate-min: 768px;
$breakpoint-intermediate-max: 999px;

// UI Elements
$global-ui-border-size: 1px;
$global-ui-border-color: $color-gray;

// Global Layout
@mixin adjust-margin-x($multiplier: 1, $responsive: true){
  margin-left: $desktop-padding * $multiplier;
  margin-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      margin-left: $mobile-padding * $multiplier;
      margin-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin page-element-padding(){
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}
@mixin page-element-margin(){
  margin-top: $desktop-padding;
  margin-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    margin-top: $mobile-padding;
    margin-bottom: $mobile-padding;
  }
}
@mixin column-padding(){
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
@mixin content-container-padding($multiplier: 1, $responsive: true){
  padding-left: $desktop-padding * $multiplier;
  padding-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      padding-left: $mobile-padding * $multiplier;
      padding-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-container-padding-vert($multiplier: 1, $responsive: true){
  padding-top: $desktop-padding * $multiplier;
  padding-bottom: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $mobile-padding * $multiplier;
      padding-bottom: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-area-width{
  width: 100%;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
}
@mixin content-area-container-style{
  background-color: $content-area-background;
  // box-shadow: 0px 2px 9px rgba(black, .05);
}
@mixin global-link-style{
  background: transparent;
  transition: background $transition-linear;
  -webkit-appearance: none;
  text-decoration: none;
  color: $color-gray-xdk;
  &:focus,
  &:hover{
    background: transparent;
  }
}
@mixin global-link-container($padding: 10px){
  display: block;
  padding: $padding;
}
@mixin global-link-text($align: left){
  text-transform: uppercase;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 800;
  text-align: $align;
}
@mixin global-link-icon-style($align: flex-start, $color: $color-primary, $hover: currentColor){
  display: flex;
  flex-flow: row;
  justify-content: $align;
  align-items: center;
  &:after{
    color: $color;
    display: inline-block;
    margin-left: 1ch;
    transition: color $transition-linear;
    text-indent: 0px !important;
  }
  &:hover:after{
    color: $hover;
  }
}
@mixin global-link-icon-character($character: "\f061", $font: FontAwesome){
  &:after{
    content: $character;
    font-family: $font;
  }
}
@mixin default-link-style{
  background: white;
  transition: color $transition-linear;
  &:focus,
  &:hover {
    color: $color-primary;
  }
}
@mixin styled-link-button{
  background-color: white;
  color: $color-primary; 
  border-radius: 1.75em;
  border: 2px solid $color-primary;
  padding: 7px 15px;
  font-size: 12px;
  text-transform: uppercase;
  transition: background-color $transition-linear, color $transition-linear;
  &:hover{
    background-color: $color-primary;
    color: white;
  }
}
// Fonts
// $font-primary: 'FFDIN', sans-serif;
// $font-secondary: 'Montserrat', sans-serif;
$enable-global-font-smoothing: true;

// Page Manager
$page-manager-width: 60px;

// Page Title
$page-title-full-width: false;
$page-title-full-width-text: false;
$page-title-container-background: $content-area-background;
$page-title-text-color: #333;
$page-title-font-size: 30px;
$page-title-text-case: null;
$page-title-font-weight: normal;
$page-title-text-align: null;

@mixin page-title-container{
  // site page title container css here
}
@mixin page-title-text{
  // border-bottom: $global-ui-border-size solid $global-ui-border-color;
  color: $color-primary;
  font-family: $font-primary;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  padding-left: 0;
  padding-right: 0;
  // site page title text css here
}
@mixin page-title-image-container{
  // site page title w/image container css here
}

// Account Navigation Options
$account-nav-bottom: true;
$account-nav-mobile-bottom: true;
$account-nav-background: #23282b;
$account-nav-color: #FFF;
$account-nav-secondary-color: $account-nav-color;
$account-nav-secondary-color-hover: darken($account-nav-color, 20%);
$account-nav-font-size: 14px;
$account-nav-height-desktop: 40px;
$account-nav-height-mobile: 44px;
$account-nav-z-index: 399;
$account-nav-icon-width: 32px;

$site-tagline-spacing: 15px; // Need this for "_source/sass/_navigation/_network_nav_bar.scss" —— Try to phase this out


// General Navigation
$pages-hidden-in-user-mode: "home"; // Example: "teams", "about us", "home";


// Title Bar Options
$nav-title-bar-height: 0px;
$nav-title-bar-height-fixed: 0px; //.01px;
$nav-title-bar-band-background-color: white;
$nav-title-bar-text-color: $color-gray-dk;
$nav-title-bar-font-family: null;
$nav-title-bar-font-size: 14px;
$nav-title-bar-line-height: 1;
$nav-title-bar-text-case: uppercase;
$nav-title-bar-text-weight: 700;


// Main Navigation Options
$main-nav-width-max: $content-area-width;
$main-nav-height: 65px;
$main-nav-height-fixed: 65px;
$main-nav-band-background-color: white;
$main-nav-flex-alignment: flex-end;
$main-nav-item-padding: 30px;

$main-nav-cascade-styles: false;
$main-nav-child-indicator: false;
$main-nav-child-indicator-cascade-styles: false;
$main-nav-more-plus-buffer: true;
$main-nav-font-family: $font-primary;
$main-nav-font-size: 14px;
$main-nav-text-case: uppercase;
$main-nav-text-align: center;
$main-nav-text-weight: normal;
$main-nav-text-color: $color-gray-dk;
$main-nav-text-color-hover: $color-primary;
$main-nav-text-color-selected: $main-nav-text-color-hover;
$main-nav-text-color-disabled: slategrey;
$main-nav-item-background-color: transparent;
$main-nav-item-background-color-hover: transparent;
$main-nav-item-background-color-selected: $main-nav-item-background-color-hover;
$main-nav-item-background-color-disabled: transparent;


// Dropdown Navigation Options
$dropdown-nav-min-width: 200px;
$dropdown-nav-min-height: 35px;
$dropdown-nav-transition-distance: 20px;
$dropdown-nav-item-padding: 30px;

$dropdown-nav-cascade-styles: true;
$dropdown-nav-child-indicator: true;
$dropdown-nav-child-indicator-cascade-styles: false;
$dropdown-nav-container-background-color: #fff;
$dropdown-nav-container-border-size: 8px;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid $color-gray-xdk;
$dropdown-nav-text-align: left;
$dropdown-nav-text-case: uppercase;
$dropdown-nav-text-weight: 500;
$dropdown-nav-font-family: $font-primary;
$dropdown-nav-font-size: 14px;
$dropdown-nav-line-height: 1.2;
$dropdown-nav-text-color: $main-nav-text-color;
$dropdown-nav-text-color-hover: $main-nav-text-color-hover;
$dropdown-nav-text-color-selected: $dropdown-nav-text-color-hover;
$dropdown-nav-text-color-disabled: $main-nav-text-color-disabled;
$dropdown-nav-item-background-color: transparent;
$dropdown-nav-item-background-color-hover: $main-nav-item-background-color-hover;
$dropdown-nav-item-background-color-selected: $dropdown-nav-item-background-color-hover;
$dropdown-nav-item-background-color-disabled: $main-nav-item-background-color-disabled;

$dropdown-nav-2-container-background-color: $dropdown-nav-container-background-color;
$dropdown-nav-2-container-border-size: null;
$dropdown-nav-2-container-border: null;
$dropdown-nav-2-text-align: null;
$dropdown-nav-2-text-case: null;
$dropdown-nav-2-text-weight: null;
$dropdown-nav-2-font-family: null;
$dropdown-nav-2-font-size: null;
$dropdown-nav-2-line-height: null;
$dropdown-nav-2-text-color: null;
$dropdown-nav-2-text-color-hover: null;
$dropdown-nav-2-text-color-selected: null;
$dropdown-nav-2-text-color-disabled: null;
$dropdown-nav-2-item-background-color: null;
$dropdown-nav-2-item-background-color-hover: null;
$dropdown-nav-2-item-background-color-selected: null;
$dropdown-nav-2-item-background-color-disabled: null;


// Sub Navigation Options
$sub-nav-hide-on-home: true;
$sub-nav-more-plus-buffer: true;
$sub-nav-font-family: $font-primary;
$sub-nav-font-size: 14px;
$sub-nav-text-color: white;
$sub-nav-text-color-hover: $color-primary;
$sub-nav-text-color-disabled: $color-gray-lt;
$sub-nav-item-background-color-disabled: transparent;
$sub-nav-band-background-color: $color-gray-dk;
$sub-nav-height: 115px;
$sub-nav-height-fixed: 115px;
$sub-nav-item-background-color: transparent;
$sub-nav-item-background-color-hover: transparent;
$sub-nav-item-text-case: uppercase;
$sub-nav-item-text-weight: 500;
$sub-nav-item-padding: 15px;
$sub-nav-flex-alignment: flex-start;

// Sub Navigation Title Options
$sub-nav-title-font-family: $font-primary;
$sub-nav-title-font-size: $sub-nav-font-size;
$sub-nav-title-height: $sub-nav-height;
$sub-nav-title-text-case: uppercase;
$sub-nav-title-text-weight: 500;
$sub-nav-title-background-color: transparent;
$sub-nav-title-text-color: white;
$sub-nav-title-padding: 15px;
$sub-nav-title-divider-content: '\f105';
$sub-nav-title-divider-font: 'fontawesome';

// Sub Navigation Dropdown Options
$sub-nav-dropdown-padding: $sub-nav-item-padding;
$sub-nav-dropdown-font-family: $font-primary;
$sub-nav-dropdown-font-size: 14px;
$sub-nav-dropdown-text-case: none;
$sub-nav-dropdown-line-height: 1.2;
$sub-nav-dropdown-min-width: 200px;
$sub-nav-dropdown-min-height: 35px;
$sub-nav-dropdown-transition-distance: 20px;
$sub-nav-dropdown-container-background-color: $sub-nav-band-background-color;
$sub-nav-dropdown-container-border: 0;
$sub-nav-dropdown-text-align: left;


// Search (Desktop) Options
$site-search-height: 26px;
$site-search-spacing: 8px;
$site-search-border-width: 1px;
$site-search-input-font-size: 14px;
$site-search-input-color: #666;
$site-search-input-border-color: rgba(255,255,255,0.1);
$site-search-input-background: rgba(255,255,255,0.8);
$site-search-input-background-focus: rgba(255,255,255,1);
$site-search-submit-color: #fff;
$site-search-submit-border-color: rgba(255,255,255,0.1);
$site-search-submit-background: $link-color-darken-10;
$site-search-submit-background-focus: $link-color-darken-20;
$site-search-submit-font-size: 12px;


// Desktop Navigation Sizes
$desktop-nav-height: $nav-title-bar-height + $main-nav-height + $sub-nav-height;
$desktop-nav-height-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed;
$desktop-nav-height-no-subnav: $nav-title-bar-height + $main-nav-height;
$desktop-nav-height-no-subnav-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed;
$nav-placeholder-height: $desktop-nav-height; // Remove after replacing these in code base
$nav-placeholder-height-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base


// Site Logo Options
$site-logo-size: $desktop-nav-height;  // Remove after replacing these in code base
$site-logo-size-fixed: $desktop-nav-height-fixed;  // Remove after replacing these in code base
$site-logo-width-desktop: $site-logo-size;
$site-logo-height-desktop: $site-logo-size;
$site-logo-width-desktop-fixed: $site-logo-size-fixed;
$site-logo-height-desktop-fixed: $site-logo-size-fixed;
$site-logo-width-mobile: 60px;
$site-logo-height-mobile: 60px;
$site-logo-padding: 5px;
$site-logo-offset: 0px; //$site-logo-width-desktop;
$site-logo-offset-fixed: 0px; //$site-logo-width-desktop-fixed;


// Mobile Nav Bar
$mobile-nav-background-color: #FFF;
$mobile-nav-toggle-right: false;
$mobile-nav-toggle-width: 44px;
$mobile-nav-toggle-height: 44px;
$mobile-nav-toggle-offset: false;
$mobile-nav-toggle-position: center;
$mobile-nav-open-toggle-position-default: false;
$mobile-nav-height: 146px;
$mobile-nav-align: center;
$mobile-nav-justification: center;


// Footer Colophon
$footer-colophon-se-logo-black-color: false;
$footer-colophon-se-logo-white-color: true;
$footer-colophon-se-logo-white: false;
$footer-colophon-max-width: $content-area-width;
$footer-colophon-band-background-color: black;
$footer-colophon-content-background-color: transparent;
$footer-colophon-item-padding: 10px;
$footer-colophon-flex-justification: center;
$footer-colophon-flex-align: center;
$footer-colophon-font-size: 12px;
$footer-colophon-line-height: 1.75;
$footer-colophon-text-case: none;
$footer-colophon-text-color: white;
$footer-colophon-link-color: white;
$footer-colophon-link-color-hover: $color-primary;


// Site Background
$site-background-image-full-height: false;


// Page Banner
$site-banner-full-width: true;
$site-banner-full-width-text: false;

$site-banner-padding: 15px;
$site-banner-fixed-min-height: 100px;
$site-banner-font-size: 20px;
$site-banner-font-size-min: 20px;
$site-banner-font-size-max: 72px;
$site-banner-text-color: white;
$site-banner-font-family: null;
$site-banner-line-height: 1;
$site-banner-font-weight: normal;
$site-banner-text-case: null;
$site-banner-text-align: center;

@mixin site-banner-container{
  // site banner container css here
}

$site-banner-headline-text-color: inherit;
$site-banner-headline-font-size: null;
$site-banner-headline-font-family: null;
$site-banner-headline-line-height: null;
$site-banner-headline-font-weight: null;
$site-banner-headline-text-case: null;
$site-banner-headline-text-align: null;
$site-banner-title-text-color: inherit;
$site-banner-title-font-size: inherit;
$site-banner-title-font-family: null;
$site-banner-title-line-height: inherit;
$site-banner-title-font-weight: inherit;
$site-banner-title-text-case: null;
$site-banner-title-text-align: null;
$site-banner-tagline-text-color: inherit;
$site-banner-tagline-font-size: null;
$site-banner-tagline-font-family: null;
$site-banner-tagline-line-height: null;
$site-banner-tagline-font-weight: null;
$site-banner-tagline-text-case: null;
$site-banner-tagline-text-align: null;


// Element Options
$element-options-flex-justification: flex-end;
$element-options-font-size: 14px;
$element-options-text-wieght: 600;
$element-options-text-case: uppercase;
$element-options-text-color: #333;
$element-options-text-color-hover: $color-primary;
$element-options-font-family: $font-primary;
$element-options-icon-margin: .7ch;
$element-options-icon-text-space: 1.5ch;


// Mixins/Functions
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

$logo-width-aaa: 160px;
$logo-width-farm: 115px;
.custom-sub-nav-extra {
  // width: 100px;
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center;
  flex: 0 0;
  display: block;
  img{
    display: block;
    padding: 5px;
    height: 100%;
    max-height: $sub-nav-height;
    // width: $sub-nav-height;
    // width: auto;
  }
}
[data-ancestor="aaa hockey club"] .custom-sub-nav-extra img{
  width: $logo-width-aaa;
}
[data-ancestor="lc farmington hills"] .custom-sub-nav-extra img {
  width: $logo-width-farm;
}
.has-main-nav .custom-site-logo-mobile {
  display: none;
}
.has-mobile-nav .custom-site-logo-desktop {
  display: none;
}
#sn-site-logo{
  display: none;
}


.theme-main-nav .theme-nav-dropdown[data-nav-level="2"] {
  list-style: none;
  // display: block;
  padding: 30px 30px 30px 210px;
  // width: 600px;
  // left: 500px;
  min-height: 300px;
  max-width: $content-area-width;
  width: 100% !important;
  left: calc(50% - #{$content-area-width/2}) !important;
  overflow: initial !important;
  html:not(.page-manager-visible) & {
    @media screen and (min-width: $breakpoint-md-min) and (max-width: $content-area-width) {
      left: 0px !important;
    }
  }
  html.page-manager-visible &{
    @media screen and (min-width: $breakpoint-md-min) and (max-width: #{$content-area-width + $page-manager-width}){
      left: 0px !important;
    }
  }
  &:before{
    content: "";
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 210px;
    width: 2px;
    background-color: $color-gray;
  }
  .dropdown-nav-logo{
    position: absolute;
    top: 0;
    left: 0;
    width: 210px;
    height: 300px;
    display: flex;
    align-content: center;
    img{
      width: 100%;
      max-width: 100%;
      padding: 30px;
    }
  }
  .dropdown-nav-title{
    width: 100%;
    font-family: $font-primary;
    font-size: 30px;
    font-weight: 800;
    color: $color-gray-xdk;
    padding: 0 30px 15px 30px;
  }
  .theme-nav-item{
    width: 33%;
    float: left;
  }
}
[data-nav-level="3"]{ display: none !important; }
.has-mobile-nav .site-tagline-text{
  display: none;
}
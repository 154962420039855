.sponsor-scroll {
  align-items: center;

  .slick-slider {

    .slick-next:before,
    .slick-prev:before {
      color: $color-gray-xdk;
    }

    .slick-prev {
      .has-mobile-nav & {
        left: 0px !important;
      }

    }

    .slick-next {
      .has-mobile-nav & {
        right: 0px !important;
      }

    }

    .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
    }

    .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
    }

    .slick-slide {
      .heroPhotoElement {
        img {
          height: auto; //100%;
          width: auto;
          max-height: 165px;
          max-width: 165px;
          transition: transform $transition-linear;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .column {
    display: flex;
    justify-content: space-around;
    align-items: center;

    // padding: 20px 100px;
    .has-mobile-nav & {
      padding: 10px 10px;
    }

    .slick-track {
      display: flex;
      align-items: center;
    }
  }

  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}
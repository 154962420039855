.padding {
  $padding-desktop: 70px;
  $padding-mobile: 28px;
  $padding-md-desktop: 40px;
  $padding-md-mobile: 28px;
  $padding-sm-desktop: 15px;
  $padding-sm-mobile: 10px;
  padding-top: $padding-desktop;
  padding-bottom: $padding-desktop;
  @media screen and (max-width: $breakpoint-sm-max) {
    padding-top: $padding-mobile;
    padding-bottom: $padding-mobile;
  }
  &-sm {
    padding-top: $padding-sm-desktop;
    padding-bottom: $padding-sm-desktop;
  }
  &-md {
    padding-top: $padding-md-desktop;
    padding-bottom: $padding-md-desktop;
  }
  &-top {
    padding-top: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-top: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max) {
        padding-top: $padding-mobile;
      }
    }
  }
  &-bottom {
    padding-bottom: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-bottom: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max) {
        padding-bottom: $padding-mobile;
      }
    }
  }
}

.compact {
  &.pageEl .pageElement,
  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
  &-top {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-top: 0;
    }
  }
  &-bottom {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}

.full-width.collapse-padding{
  padding-left: 0;
  padding-right: 0;
  > .column{
    padding-left: 0;
    padding-right: 0;
  }
}

.element-padding{
  .pageElement{
    padding: 20px;
  }
}
.element-rounded{
  .pageElement{
    border-radius: 5px;
    overflow: hidden;
  }
}
.element-bg-white{
  .pageElement{
    background-color: white;
  }
}

.background{
  &-fabric{
    background-image: url(../images/jersey_background_AAA.jpg);
    background-size: cover;
    background-position: center;
  }
  &-gray-light {
    background-color: $color-gray-lt;
  }
  &-white {
    background-color: white;
  }
  &-blue {
    background-color: #9BCBEB;
  }
  &-texture{
    background-image: url(../images/player_cutout_AAA.png), url(../images/band_noplayer.jpg);
    background-size: contain, cover;
    background-position: center right, center;
    background-repeat: no-repeat;
  }
  &-skater{
    background-image: url(../images/player_cutout_AAA.png);
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
  }
}

[data-ancestor="aaa hockey club"]{
  .background-fabric { background-image: url(../images/jersey_background_AAA.jpg); }
  .background-texture { background-image: url(../images/player_cutout_AAA.png), url(../images/band_noplayer.jpg); }
    @media screen and (max-width: $breakpoint-sm-max) { 
      .background-texture {
        background-image: url(../images/band_noplayer.jpg);
        background-size: cover;
      }
    }
}

[data-ancestor="lc farmington hills"] {
  .background-fabric { background-image: url(../images/jersey_background_farmingtonhills.jpg); }
  .background-texture { background-image: url(../images/player_cutout_Farm.png), url(../images/band_noplayer_ver2.jpg); }
  @media screen and (max-width: $breakpoint-sm-max) { 
    .background-texture {
      background-image: url(../images/band_noplayer_ver2.jpg);
      background-size: cover;
    }
  }
}


.custom-columns-md-4{
  @media screen and (min-width: $breakpoint-md-min) {
    .column{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: center;
      $derp: ((15px * 6) / 4);
      .pageEl{
        flex: 1 1;
        max-width: calc(25% - #{$derp});
        width: 100%;
      }
    }
  }
}

.textBlockElement{
  > h3{
    @include global-element-header;
  }
}

.text-light{
  .text, h1, h2, h3 , h4 , h5, h6, a{
    color: white;
  }
}
.link-light{
  .linkElement{
    h4 a{
      padding: 0;
      background-color: transparent !important;
      color: white !important;
      &:after{
        color: white;
      }
      &:hover:after{
        color: $color-primary;
      }
    }
  }
}

.contactElement .icon{
  background-color: $color-primary;
  &:hover{
    background-color: $color-gray-xdk;
  }
}
.sn-social-media-list:not(.light):not(.dark){
  .sn-social-media-icon{
    background-color: $color-primary;
    &:hover {
      background-color: $color-gray-xdk;
    }
  }
}


// Splash
.splash-link .linkElement h4{
  text-align: center;
  a{
    @include styled-link-button;
    display: inline-block;
    &:after{ display: none; }
  }
}
.user_mode .splash-container .splash-link .linkElement{
  opacity: 0;
}
.splash-container{
  padding: 0;
  display: flex;
  flex-flow: row;
  @media screen and (max-width: $breakpoint-sm-max) {
    flex-flow: column;
  }
  align-items: stretch;
  .column{
    padding: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-size: cover;
    background-position: center;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      background-color: #9BCBEB;
      opacity: 0;
      transition: opacity $transition-linear;
    }
    &:hover:before{
      opacity: .3;
    }
  }
  
  .column-1{ background-image: linear-gradient(rgba(255, 255, 255, .85), rgba(255, 255, 255, .85)), url(../images/splash_page_AAA.jpg); }
  .column-2{ background-image: linear-gradient(rgba(255, 255, 255, .85), rgba(255, 255, 255, .85)), url(../images/LC2019StateChamps.jpg); }
  .splash-link .linkElement{
    transition: opacity $transition-linear;
  }
  .column:hover > .splash-link .linkElement{
    opacity: 1;
  }
  .splash-logo{
    display: flex;
    justify-content: center;
    .heroPhotoElement{
      max-width: 300px;
      width: 100%;
      margin: auto;
    }
    [src*="LCFHH_Logo.svg"]{
      max-height: 215px;
    }
  }
}
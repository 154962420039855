/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    a{
      @include global-link-style;
      @include global-link-container;
      @include global-link-text;
      @include global-link-icon-style;
      @include default-link-style;
    }
  }
  .goToLink,
  .emailLink{
    &:before{ display:none; }
  }
  .goToLink{
    @include global-link-icon-character;
  }
  .emailLink{
    @include global-link-icon-character($character: "\f003"); // envelope-o
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}

.linkElement {
  .link-primary &{

  }
  .link-secondary &{

  }
  h4 {
    a {
    }
  }
}
.custom-quick-links{
  .textBlockElement{
    border-radius: 5px;
    overflow: hidden;
    > h3{
      background-color: $color-primary;
      padding: 20px;
      margin-bottom: 0;
      border-bottom: 1px solid white;
      font-size: 30px;
      color: white;
    }
    .text > ul li a{
      background-color: $color-primary;
      @include global-link-style;
      @include global-link-container;
      @include global-link-text;
      @include global-link-icon-character;
      @include global-link-icon-style($color: $color-primary, $hover: white);
      @include default-link-style;
      padding: 20px;
      font-size: 16px;
      background: $color-primary;
      color: white;
      text-indent: 0px;
      transition: color $transition-linear, text-indent $transition-linear;
      &:focus,
      &:hover {
        background: $color-primary;
        color: white;
        text-indent: 10px;
      } 
    }
    .text > ul{
      list-style-type: none;
      li + li a{
        border-top: 1px solid white;
      }
    }
  }
}